import {
  PostGenerateVendorTokenBodyApi,
  PostGenerateVendorTokenResponseApi,
} from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

export interface Props {
  payload: PostGenerateVendorTokenBodyApi
  accessToken: string
}
export const postGenerateVendorToken = async ({
  payload,
  accessToken,
}: Props): Promise<PostGenerateVendorTokenResponseApi> => {
  const { data } = await apiClient.post<PostGenerateVendorTokenResponseApi>(
    '/corporate-v2/v1/token',
    payload,
    { headers: getAuthorizationHeader(accessToken) }
  )

  return data
}
