import { ExternalLinks } from '_constants/externalLinks/type'

const faqDomainUrl = 'https://supporto-blank.stonly.com/'

export const BLANK_EXTERNAL_LINKS_IT: ExternalLinks = {
  cguLink:
    'https://www.blank.app/documenti-legali/condizioni-generali-servizio',
  bankingOrganizationPrivacyPolicyLink:
    'https://www.blank.app/documenti-legali/privacy-policy',
  brandPrivacyPolicyLink: '',
  appStoreLink:
    'https://apps.apple.com/it/app/blank-conto-business/id1522046776',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=app.blank.mobile&hl=it',
  faq: faqDomainUrl,
  mandateSepa: faqDomainUrl,
  invoicingFaq: faqDomainUrl,
  requestHelp: faqDomainUrl,
  rcProInfo: '',
  decadeGuaranteeInfo: '',
  multiriskInfo: '',
  legalPlaceCompany: '',
  helpNonDiffusible: faqDomainUrl,
  helpNonEligible: faqDomainUrl,
  helpBeneficialOwners: faqDomainUrl,
  urssaf: {
    portal: '',
    microEntrepreneurs: '',
    socialContributions: '',
    sepaMandate: '',
    faq: '',
    tdaeStatusHelp: '',
    tdaePaymentAuthorization: '',
    tou: '',
  },
  faqWhoIsBlankFor: faqDomainUrl,
  psdFAQ: faqDomainUrl,
  legalLink: 'https://it.blank.app/documenti-legali',
  supportEmail: 'servizio.clienti@blank.app',
  securityEmail: 'servizio.clienti@blank.app',
  leadEmail: 'servizio.clienti@blank.app',
  sepaTransfer: faqDomainUrl,
  compareOffers: 'https://it.blank.app/tariffe',
  touBankAccount: 'https://blank.app/documenti-legali/conto-di-pagamento',
  transparencyPolicy:
    'https://www.blank.app/documenti-legali/documento-sintesi-conto',
  kycHelp: faqDomainUrl,
  legalStatus: '',
  capitalDeposit: '',
  discoverManagementToolsOffer: '',
  sponsorshipFAQ: '',
  priceBrochure: 'https://www.blank.app/documenti-legali/foglio-informativo',
  changeNonDiffusibleCompany: '',
  sponsorshipInvitationLink: '',
  requestDeviceResetFAQ:
    'https://supporto-blank.stonly.com/kb/guide/it/tutto-sul-dispositivo-di-riferimento-q5nxQu5Vbp/Steps/2998037',
  accountClosingFaq:
    'https://supporto-blank.stonly.com/kb/guide/it/come-posso-chiudere-il-mio-conto-rYJlWB5lWF/Steps/2457759',
  bankScamPreventionLink: '',
  transferFAQ: `${faqDomainUrl}/kb/guide/it/come-funzionano-i-bonifici-10dDf3CSN7/Steps/2558352`,
}
