export enum EnumSupportKey {
  NOT_VALIDATED = 'notValidated',
  SUPPORT_COMPLETE = 'supportComplete',
  SUPPORT_CONFORT = 'supportConfort',
  SUPPORT_SIMPLE = 'supportSimple',
}

export enum EnumSupportModalTabs {
  HELP = 'HELP',
  COMPLAINT = 'COMPLAINT',
}

export interface SupportTouchpoints {
  faqURL?: string
  emailSupport?: string
  phoneSupport?: string
  whatsAppSupportNumber?: string
}
