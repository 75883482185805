import { EnumBillingServiceId, EnumCorporateStatus } from '@blank/api'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { EnumSupportKey } from 'modules/common/types/support'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'
import { useCorporateStatusQuery } from 'modules/corporate/queries/useCorporateStatusQuery'

export const useSupportContext = (): EnumSupportKey => {
  const { data: user } = useAuthenticatedUserQuery()
  const { data: corporateStatus } = useCorporateStatusQuery(user?.corporateId)
  const { data: offer } = useCorporateOfferQuery(user?.corporateId)
  const offerPackages = offer?.offerPackages

  if (corporateStatus !== EnumCorporateStatus.VALIDATED || !offerPackages) {
    return EnumSupportKey.NOT_VALIDATED
  }

  let supportType = EnumSupportKey.NOT_VALIDATED

  // Check the offer and return the best support available
  for (let i = 0; i < offerPackages.length; i++) {
    switch (offerPackages[i].serviceId) {
      case EnumBillingServiceId.SUPPORT_COMPLETE:
        return EnumSupportKey.SUPPORT_COMPLETE
      case EnumBillingServiceId.SUPPORT_CONFORT:
        supportType = EnumSupportKey.SUPPORT_CONFORT
        break
      case EnumBillingServiceId.SUPPORT_SIMPLE:
        if (supportType !== EnumSupportKey.SUPPORT_CONFORT) {
          supportType = EnumSupportKey.SUPPORT_SIMPLE
        }
        break
      default:
    }
  }
  return supportType
}
