const getWidgetLauncherContainer = () => {
  const zendeskLauncher = document.querySelector('iframe#launcher')
  const zendeskLauncherContainer = zendeskLauncher?.parentElement?.parentElement

  return zendeskLauncherContainer
}

export const resetZendeskMessaging = () => {
  if (window.zE) {
    window.zE('messenger', 'resetWidget')
  }
}
export const showZendeskMessaging = () => {
  const zendeskLauncherContainer = getWidgetLauncherContainer()
  if (zendeskLauncherContainer) {
    zendeskLauncherContainer.style.display = 'block'
  }
}

export const hideZendeskMessaging = () => {
  const zendeskLauncherContainer = getWidgetLauncherContainer()
  if (zendeskLauncherContainer) {
    zendeskLauncherContainer.style.display = 'none'
  }
}

export const loginWithZendeskMessaging = (jwtToken: string) => {
  if (window.zE) {
    window.zE(
      'messenger',
      'loginUser',
      function jwtCallback(callback: any) {
        callback(jwtToken)
      },
      function loginCallback(error: any) {
        if (error) {
          console.error('loginWithZendeskMessaging', error)
        }
      }
    )
  }
}
