import { EnumFiscalResidence } from '@blank/api'
import { CountryConfig, EnumHomeCountryCode } from '../../types'

export const BLANK_FR_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 9,
  shouldChooseDomiciliationOnSignup: false,
  isBeta: false,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: false,
  isSponsorshipEnabled: true,
  increaseTransferLimitTypeformId: 'C9OWESfP',
  feedbackTypeformId: '',
  accountClosingTypeformId: 'YdO8BtiT',
  shouldDisplayAdditionalTouOnSelectOffer: false,
  fiscalResidenceOptions: [EnumFiscalResidence.FRA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.FRA,
  invoicing: {
    canCreateEstimateDocument: true,
    canAddFiscalTaxesToDocument: false,
    canAddFiscalStampToDocument: false,
    shouldSelectVATExonerationCode: false,
  },
  shouldDisplayAverageBalanceDocuments: false,
  shouldBuildMicroEnterpriseCreationURLWithParams: false,
  whatsAppSupportNumber: undefined,
  canDepositCheck: true,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: true,
  fileComplaintTypeformId: 'KHlIE416',
  shouldDisplayZendeskMessagingWidget: true,
}
