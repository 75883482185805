import {
  EnumScaActionStatus,
  EnumScaActionType,
  ScaActionApi,
} from '@blank/api'
import { EnumLocalStorageKeys } from '_constants'
import dayjs from 'dayjs'
import localStorage from 'modules/common/lib/storage/localStorage'
import { ScaLoginAction } from './types'

export const DEFAULT_TIMER_DURATION = 300
const LOGIN_SCA_ACTION_DAYS_LIMIT = 180

export const getScaActionTimerDuration = (
  actionCreatedAt: string | undefined
) => {
  let elapsedTime = 0
  if (actionCreatedAt) {
    elapsedTime = (Date.now() - new Date(actionCreatedAt).getTime()) / 1000
  }
  return DEFAULT_TIMER_DURATION - elapsedTime
}

export const setScaLoginAction = (scaLoginAction: ScaLoginAction) => {
  localStorage.store(EnumLocalStorageKeys.SCA_LOGIN_ACTION, scaLoginAction)
}

export const getScaLoginAction = () => {
  return localStorage.retrieve<ScaLoginAction | undefined>(
    EnumLocalStorageKeys.SCA_LOGIN_ACTION
  )
}

interface ScaLoginActionApi extends ScaActionApi {
  type: EnumScaActionType.CONNECTION
}
export function isScaLoginActionApi(
  action: ScaActionApi
): action is ScaLoginActionApi {
  return action.type === EnumScaActionType.CONNECTION
}
export const transformScaLoginAction = (
  scaActionResponse: ScaLoginActionApi
) => {
  const scaActionCreatedAt = dayjs(scaActionResponse.createdAt)
  const maxLoginActionValidDate = dayjs().subtract(
    LOGIN_SCA_ACTION_DAYS_LIMIT,
    'days'
  )
  const isLoginScaActionExpired = scaActionCreatedAt.isBefore(
    maxLoginActionValidDate
  )

  return {
    ...scaActionResponse,
    isExpired: isLoginScaActionExpired,
    status: isLoginScaActionExpired
      ? EnumScaActionStatus.EXPIRED
      : scaActionResponse.status,
  } satisfies ScaActionApi
}
