import { EnumTokenVendors } from '@blank/api'
import { usePostGenerateVendorTokenMutation } from 'modules/auth/mutations/usePostGenerateVendorTokenMutation'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import {
  hideZendeskMessaging,
  loginWithZendeskMessaging,
  showZendeskMessaging,
} from 'modules/common/lib/zendesk/zendeskMessagingWidgetUtils'
import Script from 'next/script'
import { useCallback, useEffect } from 'react'

export const ZendeskMessagingScript = () => {
  const { data: user } = useAuthenticatedUserQuery()
  const { shouldDisplayZendeskMessagingWidget } = useCountryConfig()

  const { mutate: postGenerateVendorToken } =
    usePostGenerateVendorTokenMutation({
      onSuccess: (response) => {
        loginWithZendeskMessaging(response.data.token)
      },
      onSettled: () => {
        showZendeskMessaging()
      },
    })

  useEffect(() => {
    onLoad()
    // We need onLoad to be ran whenever corporateId changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.corporateId])

  const onLoad = useCallback(() => {
    if (user?.corporateId && shouldDisplayZendeskMessagingWidget) {
      postGenerateVendorToken({ vendor: EnumTokenVendors.ZENDESK })
    } else {
      hideZendeskMessaging()
    }
  }, [
    postGenerateVendorToken,
    shouldDisplayZendeskMessagingWidget,
    user?.corporateId,
  ])

  if (!shouldDisplayZendeskMessagingWidget) {
    return null
  }

  return (
    <div>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=89ed6c2a-1f9c-48eb-831d-2ad30922ad70"
        onLoad={onLoad}
      />
    </div>
  )
}
