import { IconEmailRead } from 'modules/common/components/_icons/icons'
import { BackgroundIcon } from 'modules/common/components/_ui/BackgroundIcon/BackgroundIcon'
import { ListItem } from 'modules/common/components/_ui/ListItem/ListItem'
import { CopyText } from 'modules/common/components/CopyText/CopyText'
import { Link } from 'modules/common/components/Link/Link'
import { useSupportContext } from 'modules/common/hooks/useSupportContext'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  emailSupport: string
}

export const EmailTouchpointListItem = ({ emailSupport }: Props) => {
  const { t } = useTranslation('common-components')

  const supportContext = useSupportContext()

  return (
    <ListItem
      titlePosition="top"
      title={<Link href={`mailto:${emailSupport}`}>{emailSupport}</Link>}
      subtitle={t('supportModal.supportTouchpoints.emailDetails', {
        context: supportContext,
      })}
      icon={
        <BackgroundIcon
          color="important"
          size="small"
          variant="light"
          icon={<IconEmailRead />}
        />
      }
      rightElement={<CopyText textToCopy={emailSupport} />}
    />
  )
}
