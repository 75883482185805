import {
  PostGenerateVendorTokenBodyApi,
  PostGenerateVendorTokenResponseApi,
} from '@blank/api'
import { UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMutationWithAuth } from 'modules/auth/hooks/useMutationWithAuth'
import { postGenerateVendorToken } from '../api/postGenerateVendorToken'

export const usePostGenerateVendorTokenMutation = (
  mutationOptions?: UseMutationOptions<
    PostGenerateVendorTokenResponseApi,
    AxiosError,
    PostGenerateVendorTokenBodyApi
  >
) => {
  return useMutationWithAuth<
    PostGenerateVendorTokenResponseApi,
    AxiosError,
    PostGenerateVendorTokenBodyApi
  >({
    mutationFn: async ({ accessToken, ...payload }) => {
      return postGenerateVendorToken({
        accessToken,
        payload,
      })
    },
    ...mutationOptions,
  })
}
